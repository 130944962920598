import ReactDOM from 'react-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { BsToggle2Off, BsToggle2On } from 'react-icons/bs';

const MODAL_TIMEOUT = 1500;
const MOBILE_MAX_WIDTH = 768;

export default function Cookies({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-settings']);
  const [showModal, setShowModal] = useState(!cookies['cookie-settings']);
  const [isVisible, setIsVisible] = useState(false);
  const [cookieConsent, setCookieConsent] = useState({ analytics: true });
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  // Сохранение выбранного языка
  useEffect(() => {
    if (currentLanguage) {
      localStorage.setItem('i18nextLng', currentLanguage);
    }
  }, [currentLanguage]);

  // Таймер для отображения модального окна
  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => setIsVisible(true), MODAL_TIMEOUT);
      return () => clearTimeout(timer);
    } else {
      document.body.classList.remove('overflow-y-hidden'); // Удаляем класс при закрытии
    }
  }, [showModal]);

  // Управление прокруткой страницы
  useEffect(() => {
    if (isVisible && window.innerWidth < MOBILE_MAX_WIDTH) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-y-hidden');
    };
  }, [isVisible]);

  // Активировать Google Analytics
  const activateGoogleAnalytics = useCallback(() => {
    if (!window.gtag) {
      const GA_TRACKING_ID = 'G-PK70TN2X21';
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      script.async = true;

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(['js', new Date()]);
        window.dataLayer.push(['config', GA_TRACKING_ID]);
      };

      script.onerror = () => console.error('Failed to load Google Analytics script.');

      document.body.appendChild(script);
    }
  }, []);

  // Обновить настройки согласия
  const updateConsent = useCallback(type => {
    setCookieConsent(prev => ({ ...prev, [type]: !prev[type] }));
  }, []);

  // Принять cookies
  const handleAccept = () => {
    setCookie('cookie-settings', 'accepted', { path: '/' });
    setShowModal(false); // Закрыть модальное окно
    if (cookieConsent.analytics) activateGoogleAnalytics();
  };

  // Отклонить cookies
  const handleDecline = () => {
    removeCookie('cookie-settings', { path: '/' });
    setShowModal(false); // Закрыть модальное окно
  };

  // Рендер переключателя
  const renderToggleIcon = useCallback(
    isEnabled => (
      <button onClick={() => updateConsent('analytics')}>{isEnabled ? <BsToggle2On className="text-[1.5rem] text-green-600" /> : <BsToggle2Off className="text-[1.5rem] text-red-600" />}</button>
    ),
    [updateConsent]
  );

  const modalClasses = ` 
    fixed bottom-0 left-0 z-[999] bg-white dark:bg-black sm:border-t lg:border border-neutral-200 dark:border-neutral-600 lg:shadow-md
    w-full lg:max-w-[38rem] h-full sm:h-auto py-14 px-5 sm:p-5 transform duration-500 overflow-y-auto
    ${isVisible ? 'translate-y-0 lg:-translate-y-5 lg:translate-x-5' : 'translate-y-full'}
  `;

  return (
    <>
      {showModal &&
        ReactDOM.createPortal(
          document.getElementById('modal') && (
            <div className={modalClasses}>
              <div className="w-full flex flex-col justify-between gap-5">
                <div className="cookies-modal-content">
                  <p className="text-[1.2rem] text-black dark:text-white font-semibold mb-2">{t('cookies.title')}</p>
                  <p className="text-[1rem] sm:text-[0.9rem] text-black dark:text-white" dangerouslySetInnerHTML={{ __html: t('cookies.text') }}></p>
                </div>
                <div className="flex items-center">
                  {renderToggleIcon(cookieConsent.analytics)}
                  <button className="text-[1rem] text-black dark:text-white ml-2" onClick={() => updateConsent('analytics')}>
                    {t('cookies.google-analytics')}
                  </button>
                </div>
                <div className="w-full flex flex-col sm:flex-row gap-4 justify-end lg:justify-center">
                  <button className="btn-cookies active" onClick={handleAccept} title={t('cookies.btn-accept')}>
                    {t('cookies.btn-accept')}
                  </button>
                  <button className="btn-cookies" onClick={handleDecline} title={t('cookies.btn-no-accept')}>
                    {t('cookies.btn-no-accept')}
                  </button>
                </div>
              </div>
            </div>
          ),
          document.getElementById('modal')
        )}
      {children}
    </>
  );
}

export const isCookieAccepted = () => document.cookie.includes('cookie-settings=accepted');
